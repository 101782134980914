import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
    <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Hebammenteam Coming Home",
          "alternateName": "Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/",
          "logo": "/src/images/small_logo.png",
          "description": "Coming Home steht bei uns für eine liebevolle und kompetente Hebammenbetreuung. Wir begleiten Sie auf Ihrem Weg des Elternwerdens.",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+4915151522921",
            "contactType": "customer service",
            "contactOption": "HearingImpairedSupported",
            "areaServed": "DE",
            "availableLanguage": "German"
          },
          "sameAs": [
            "https://hebammenteam.info/"
          ]
        }
      `}</script>
      {title == "Herbammenteam" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Hebammenteam Coming Home",
          "alternateName": "Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/",
          "description": "Coming Home steht bei uns für eine liebevolle und kompetente Hebammenbetreuung. Wir begleiten Sie auf Ihrem Weg des Elternwerdens.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://hebammenteam.info/#website",
            "url": "https://hebammenteam.info/",
            "name": "Hebammenteam Coming Home",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/",
                "url": "https://hebammenteam.info/",
                "name": "Shop"
              }
            }
          }]
        }
      `}</script>
      }
      {title == "Leistungen" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Leistungen - Hebammenteam Coming Home",
          "alternateName": "Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/",
          "description": "Unsere Leistungen für ihren Weg des Elternwerdens.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://hebammenteam.info/#website",
            "url": "https://hebammenteam.info/",
            "name": "Hebammenteam Coming Home",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/",
                "url": "https://hebammenteam.info/",
                "name": "Shop"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/leistungen",
                "url": "https://hebammenteam.info/leistungen",
                "name": "Shop"
              }
            }]
          }]
        }
      `}</script>
      }
      {title == "Philosophie" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Philosophie - Hebammenteam Coming Home",
          "alternateName": "Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/",
          "description": "Coming Home steht bei uns für eine liebevolle und kompetente Hebammenbetreuung. Wir begleiten Sie auf Ihrem Weg des Elternwerdens.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://hebammenteam.info/#website",
            "url": "https://hebammenteam.info/",
            "name": "Hebammenteam Coming Home",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/",
                "url": "https://hebammenteam.info/",
                "name": "Shop"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/philosophie",
                "url": "https://hebammenteam.info/philosophie",
                "name": "Shop"
              }
            }]
          }]
        }
      `}</script>
      }
      {title == "Kontakt" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Kontakt - Hebammenteam Coming Home",
          "alternateName": "Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/",
          "description": "Coming Home steht bei uns für eine liebevolle und kompetente Hebammenbetreuung. Wir begleiten Sie auf Ihrem Weg des Elternwerdens.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://hebammenteam.info/#website",
            "url": "https://hebammenteam.info/",
            "name": "Hebammenteam Coming Home",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/",
                "url": "https://hebammenteam.info/",
                "name": "Shop"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/kontakt",
                "url": "https://hebammenteam.info/kontakt",
                "name": "Shop"
              }
            }]
          }]
        }
      `}</script>
      }
      {title == "Kurse" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Kurse und Anmeldung - Hebammenteam Coming Home",
          "alternateName": "Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/",
          "description": "Coming Home steht bei uns für eine liebevolle und kompetente Hebammenbetreuung. Wir begleiten Sie auf Ihrem Weg des Elternwerdens.",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://hebammenteam.info/#website",
            "url": "https://hebammenteam.info/",
            "name": "Hebammenteam Coming Home",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/",
                "url": "https://hebammenteam.info/",
                "name": "Shop"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/kurse-und-anmeldung",
                "url": "https://hebammenteam.info/kurse-und-anmeldung",
                "name": "Shop"
              }
            }]
          }]
        }
      `}</script>
      }
      {title == "Impressum" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Impressum - Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/impressum",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://hebammenteam.info/#website",
            "url": "https://hebammenteam.info/",
            "name": "Hebammenteam Coming Home",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/",
                "url": "https://hebammenteam.info/",
                "name": "Shop"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/impressum",
                "url": "https://hebammenteam.info/impressum",
                "name": "Shop"
              }
            }]
          }]
        }
      `}</script>
      }
      {title == "Datenschutz" &&
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@graph":[{
          "@type": "WebPage",
          "name": "Datenschutz - Hebammenteam Coming Home",
          "url": "https://hebammenteam.info/datenschutz",
          "inLanguage": "de-DE",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://hebammenteam.info/#website",
            "url": "https://hebammenteam.info/",
            "name": "Hebammenteam Coming Home",
            "inLanguage": "de-DE"
            }
          },{
            "@type": "BreadcrumbList",
            "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/",
                "url": "https://hebammenteam.info/",
                "name": "Shop"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "item": {
                "@type": "WebPage",
                "@id": "https://hebammenteam.info/#datenschutz",
                "url": "https://hebammenteam.info/datenschutz",
                "name": "Shop"
              }
            }]
          }]
        }
      `}</script>
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
